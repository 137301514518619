import React from 'react'

function Hero() {
  return (
    <div className='bg-gray-200  font-serif w-full flex md:p-5 p-2 pt-8 justify-center'>
      <div className=' w-full md:border  flex  md:pt-8  h-full md:border-black md:border-solid rounded-xl '>
        <div className='  p-8 md:mb-32 mt-10 flex-wrap h-full items-center '>
          <div className='text-black  flex-shrink md:text-8xl text-7xl animate-fade-up'>Hey, I’m <span className='font-semibold'>Dylan</span>. <span className=''>👋</span></div>
            <div className='text-black md:text-4xl text-2xl max-w-3xl mt-10 animate-fade-up'>I'm a web and app developer, who one day wants to change the world. I make apps and websites. </div>
            
            <div className=' md:mt-40 mt-16 items-center justify-center md:itmes-start md:justify-start md:block flex'>
            <a href='https://github.com/CodingDylan'>
              <button  className='animate-fade-right bg-white px-2 py-2 rounded-lg items-center justify-center hover:bg-gray-300 shadow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33c.85 0 1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"/></svg>
              </button>
            </a>
            
            <a href='https://www.linkedin.com/in/dylan-ngere-375272299/'>
              <button  className='animate-fade-right bg-white px-2 py-2 rounded-lg items-center justify-center hover:bg-gray-300 ml-3 shadow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"/></svg>
              </button>
            </a>
            

            </div>

          
        </div>
        
        <div className='items-center justify-center flex'>
          <img src="/wireframe-sphere.png" alt="Wireframe Sphere" className=' animate-fade h-auto max-w-full mx-auto  object-cover ml-10 hidden xl:block'/>
        </div>
        

      </div>
      
    </div>
    
  )
}

export default Hero