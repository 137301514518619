
import React from 'react'


function Announcement({ text, link, closeable = false }) {

  return (
    <>
    {/* {showAnnouncement &&  */}
        <div className='w-full bg-red-600 text-white font-mono h-10 items-center justify-center flex font-bold md:text-base text-xs text-center'> 
    {/* <Link to={link}>{text} </Link> */}
    <div>{text} </div>
    {/* {closeable && <button className='ml-10' onClick={() => setShowAnnouncement(false)}>
    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            
                        >
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>

    </button> } */}
    </div>
    </>
    
  )
}

export default Announcement