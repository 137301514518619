import React from 'react'

function Info() {
  return (
    <div className='bg-black text-white font-serif w-full md:p-16  p-8 content-between' >
      <div className='bg-black w-full h-px  block md:hidden'/>
      <div className='w-full h-px mb-10 mb:hidden '/>
          <div className='animate-fade-up md:text-8xl  text-6xl flex items-end'>
              Info<span><svg width="100" height="100"  className="animate-fade-up hidden md:block" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="M18 7.05a1 1 0 0 0-1-1L9 6a1 1 0 0 0 0 2h5.56l-8.27 8.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0L16 9.42V15a1 1 0 0 0 1 1a1 1 0 0 0 1-1Z"/></svg><svg width="40" height="40"  className=" animate-fade-up md:hidden" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="M18 7.05a1 1 0 0 0-1-1L9 6a1 1 0 0 0 0 2h5.56l-8.27 8.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0L16 9.42V15a1 1 0 0 0 1 1a1 1 0 0 0 1-1Z"/></svg></span>
          </div>
          <div className='bg-black  flex-wrap'>
            <div className='  animate-fade-up md:text-5xl text-white text-4xl md:mt-32 mt-16 font-semibold md:w-4/5 '>
            “The Future belongs to those who believe in the beauty of their dreams.”
            </div>
            <div  className='pt-16 mb-32 md:mb-64 md:mt-32 pb-32 md:pb-20'>
              <div className='animate-fade-up md:text-4xl text-base w-3/4 text-white absolute right-10 bg-black text-right    '>
            
           
              The world is ever changing and I believe that a strong leader should be leading the future. My goal is to start my own company that makes
               the world a better place and advances society to the future. I plan to revolutionise an industry and make a difference in the world.
                 I am a dreamer and I am always looking to make my dreams a reality and I am a believer and I am always looking to believe in myself. 
              </div>


            </div>

            <div  className='pt-16  md:mb-32 md:mt-32 pb-32 md:pb-20'>
              <div className=' animate-fade-right md:text-4xl text-base w-3/4 text-white absolute  bg-black    '>
            
           
              "My dream is to one day is to have my own research institute which focusses on developing new technologies which will transform the world."  
              </div>

              
            </div>
      </div>
    
    </div>
  )
}

export default Info