import React from 'react'
import "../App.css"

function Skills() {
    const skills = ['JS', 'HTML', 'CSS', 'PYTHON', 'REACT', 'FIREBASE', 'JS', 'HTML', 'CSS', 'PYTHON', 'REACT', 'FIREBASE']

  return (
    <div className='bg-gray-200  w-full inline-block font-serif '>
        <div className='md:text-9xl text-8xl mx-auto text-center mt-20 animate-fade'>Skills</div>
        <div className='w-full mt-12 flex-row flex justify-start items-center overflow-hidden animate-fade border-black border-solid border '>
            <div className='items-center justify-start flex w-full carousel-track ' >
                {skills.map((skill, index) => (

                        <div key={index} className=' rounded-lg justify-center items-center md:px-10 md:py-10 px-10 py-20 md:ml-32 ml-16'>
                            <img src={`/${skill}.png`} alt={`${skill} Logo`} className='h-auto max-w-xl mx-auto object-cover ' width={200} height={100}/>
                        </div>
                    )
                )}
                {skills.map((skill, index) => (

<div key={index} className=' rounded-lg justify-center items-center md:px-10 md:py-10 px-10 py-20 md:ml-32 ml-16'>
    <img src={`/${skill}.png`} alt={`${skill} Logo`} className='h-auto max-w-xl mx-auto object-cover ' width={200} height={100}/>
</div>
)
)}
{skills.map((skill, index) => (

<div key={index} className=' rounded-lg justify-center items-center md:px-10 md:py-10 px-10 py-20 md:ml-32 ml-16'>
    <img src={`/${skill}.png`} alt={`${skill} Logo`} className='h-auto max-w-xl mx-auto object-cover ' width={200} height={100}/>
</div>
)
)}
                

            </div>
            

        </div>
        <div className='bg-black text-white md:p-16  p-8'>
        <div className='bg-white w-full h-0.5 mx-auto md:mt-32 mt-16'/>
        <div className='md:text-6xl text-3xl mx-auto text-center mt-10 animate-fade-up'>Over 3+ years of developer experience</div>
        <div className='flex flex-col md:flex-row mt-12 md:mt-16 justify-around'>
            <div className='border-2 border-gray-200 rounded-lg border-solid justify-center items-center md:w-1/5 md:px-10 md:py-32  px-5 py-16 lg:mb-0 mb-10'>
                <div className='md:text-5xl text-7xl font-bold text-center'>3+</div>
                <div className='md:text-2xl  text-4xl text-center mt-5'>programming languages learnt</div>   
            </div>

            <div className='border-2 border-gray-200 rounded-lg border-solid justify-center items-center md:w-1/5 md:px-10 md:py-32  px-5 py-24  flex-col lg:mb-0 mb-10'>
                <div className='md:text-4xl text-5xl text-center flex items-center justify-center'>over <span className='font-bold ml-2'>10,000+</span></div>
                <div className='md:text-2xl text-2xl text-center  mt-5'>lines of code written</div>   
            </div>
            <div className='border-2 border-gray-200 rounded-lg border-solid justify-center items-center md:w-1/5 md:px-10 md:py-32  px-5 py-16  flex-col  lg:mb-0 mb-10'>
                <div className='md:text-5xl text-6xl text-center '><span className='font-bold block md:inline pb-2 md:pb-0'>4</span> projects</div>
                <div className='md:text-2xl text-4xl text-center  mt-5'>made with 💘</div>   
            </div>
            <div className='border-2 border-gray-200 rounded-lg border-solid justify-center items-center md:w-1/5 md:px-10 md:py-32  px-5 py-16  flex-col  lg:mb-0 mb-10'>
                <div className='md:text-5xl text-7xl text-center font-bold'>3+</div>
                <div className='md:text-2xl text-4xl text-center  mt-5'>programming languages learnt</div>   
            </div>
            

           
        </div>
        <div className='bg-white  w-full h-0.5 mx-auto md:mt-32 mt-18'/>
        
        </div>
        
    </div>
  )
}

export default Skills