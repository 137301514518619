import React, {useState} from 'react'
import Header from '../components/Header'
import Hero from '../components/Hero'
// import Info from '../components/Info'
import FutureDesign from '../components/FutureDesign'
import Info from '../components/Info'
import Skills from '../components/Skills'
import Footer from '../components/Footer'
import Projects from '../components/Projects'
import Announcement from '../components/Announcement'




function Home() {
    const [showCookiesPopup, setShowCookiesPopup] = useState(JSON.parse(localStorage.getItem('cookies-popup'))); // JSON.parse(localStorage.getItem('cookies-popup'))

    const handleCookiesPopupClose = () => {
        localStorage.setItem('cookies-popup', JSON.stringify(false));
        setShowCookiesPopup(false);
    };


    return (
        <div>
            {showCookiesPopup && (
                <div
                    className=' fixed bottom-0 left-0 w-full bg-white border-t-black border rounded  h-16 md:h-20 z-50 p-4 flex justify-between items-center'
                    
                >
                    <span className='text-black text-xs  md:text-xl'>This website uses cookies. By continuing to use this site, you consent to the use of cookies.</span>

                    <div className='flex'>
                    <button className='bg-black text-white text-base md:text-xl font-semibold rounded py-1 md:px-4 px-2 mr-1 md:mr-2'>
                        Accept
                    </button>
                    <button className='border-black border border-solid text-base md:text-xl text-black font-semibold rounded py-1 md:px-4 px-2  mr-1 md:mr-2'>
                        Decline
                    </button>
                    <button
                        className=' border-none bg-transparent cursor-pointer'
                        
                        onClick={handleCookiesPopupClose}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </button>
                    </div>
                    
                </div>
            )}

            <Announcement text="Big things are coming 2024-2025, Stay tuned!" link="/" closeable={true} />
            <Header />
            <Hero />
            <Info />
            <FutureDesign />
            {/* <About /> */}
            <Skills />
            <Projects />
            <Footer />
        </div>
    );
}

export default Home