import React from 'react'
import Header from '../components/Header'

function NotFound() {
  return (
    <div className='bg-gray-200 min-h-screen w-full'>
        <Header />
        <div className='text-black md:text-4xl text-2xl text-center justify-center mt-48 min-h-screen w-full flex font-bold'>
          <h1>404 - The page you are looking for does not exist</h1>
        </div>
    </div>
    
  )
}

export default NotFound