
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        {/* <Route path="/blog" element={<Blog />}/> */}
        <Route path="/contactme" element={<Contact />}/>
        {/* <Route path="/improveme" element={<Enhance />}/> */}
        <Route path="*" element={<NotFound />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
