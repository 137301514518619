import React, { useEffect, useState } from 'react'
import Header from '../components/Header'


function Contact() {
  const [time, setTime] = useState(1)
  const [weeks, setWeeks] = useState(0)
  const [passed, setPassed] = useState(0)
  const [display, setDisplay] = useState(false)

  function calculateSecondsUntilDeath(deathDate) {
    
    // Calculate the difference in milliseconds between death date and current date
    const differenceMs = deathDate - new Date();

    // Convert milliseconds to seconds
    const secondsUntilDeath = Math.floor(differenceMs / 1000);
    
    return secondsUntilDeath;
  }

  function callback(date) {
    setTimeout(() => {
      setTime(calculateSecondsUntilDeath(date))
      callback(date)
    
    }, 1000)
    
  }

  function displaystuff() {
    setDisplay(!display)
  }
  function calculateWeeks(startDate, endDate) {
    // Convert both dates to milliseconds
    const startMillis = startDate.getTime();
    const endMillis = endDate.getTime();

    // Calculate the difference in milliseconds
    const differenceMillis = Math.abs(endMillis - startMillis);

    // Convert the difference to weeks
    const weeksDifference = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24 * 7));

    // Calculate how many weeks have already passed
    const currentDate = new Date();
    const currentMillis = currentDate.getTime();
    const weeksPassed = Math.ceil(Math.abs(currentMillis - startMillis) / (1000 * 60 * 60 * 24 * 7));

    return {
        totalWeeks: weeksDifference,
        weeksPassed: weeksPassed - 1
    };
  }

  function renderWeeks() {
    const divs = [];
    let beginning = 0
    let count = 1
    for (let i = 1; i <= weeks; i++) {
      if (beginning === 52) {
        beginning = 0
      } 
      if (beginning === 0) {
         divs.push(<div className='text-black font-bold text-lg pb-1'>{count}</div> ); 
         count++

      } 
      if (i <= passed) {
          divs.push(<div key={i} className="w-5 h-5 bg-black "></div>);
      } else {
        divs.push(<div key={i} className="w-5 h-5 bg-white "></div>);
    }

      beginning++
    }
    return divs;
  }


  useEffect(() => {
    

    // Example usage:
    // Convert birth date string to a Date object
    const birthDateTime = new Date("2007-09-25");

    // Calculate death date 80 years ahead
    const deathDate = new Date(birthDateTime);
    deathDate.setFullYear(deathDate.getFullYear() + 80);
    setTime(calculateSecondsUntilDeath(deathDate))

    const { totalWeeks, weeksPassed } = calculateWeeks(birthDateTime, deathDate);
    setPassed(weeksPassed)
    setWeeks(totalWeeks)

    callback(deathDate)

    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='bg-gray-200 w-full min-h-screen pt-4'>
        <Header />
        <div className='p-10 w-full'>
            <div className='text-4xl font-bold  animate-fade-up'>Contact <span onClick={displaystuff}>Me</span></div>
            {/* <div className='md:text-2xl  text-xl mt-3  animate-fade-right'>Here are my contact details:</div>
            <div className='md:text-2xl  text-xl mt-3  animate-fade-right'><span className='font-bold'>Email:</span> dylanngere0@gmail.com</div> */}
            
            {display && <div className='mt-4 hidden md:block'>
                <div className='md:text-2xl text-xl mt-3 text-center'>You have <span className='font-bold'>{time}</span> seconds left of your life</div>
             
                <div className='mt-5 grid grid-columns gap-2' >
                  {renderWeeks()}
                </div>
            </div> }
        </div>
    </div>
  )
}

export default Contact