import React from 'react'

function Projects() {
  return (
    <div className='bg-gray-200  font-serif'>
        <div className='text-6xl md:p-16 p-6 md:text-9xl mb-8  animate-fade-up'>Projects</div>
        <div>
        <div className='w-full bg-black text-white md:py-16 py-8 flex px-10 items-center animate-fade-up'>
                <div className='xl:text-3xl lg:text-2xl md:text-xl  '>1.</div>
                <div className='xl:text-5xl lg:text-4xl md:text-2xl md:ml-10 ml-4 text-center'>Sneaker finding website</div>
                <div className='bg-white w-px md:ml-10 ml-5 text-white h-24' />
                <div className='xl:text-5xl lg:text-4xl md:text-base md:ml-10 ml-3 font-light max-sm'>React, Axios, CSS </div>
                <a href='https://github.com/CodingDylan/sneaker-app'><button className='md:text-3xl text-xl border border-white hidden lg:block border-solid rounded-xl items-center justify-center md:px-14 px-4 absolute md:py-6 py-2 right-10 md:right-20'>
                    Visit
                </button></a>
                <a href='https://github.com/CodingDylan/sneaker-app'><button className='block lg:hidden p-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13.5" viewBox="0 0 16 9"><path fill="currentColor" d="M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5"/><path fill="currentColor" d="M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71c.2-.2.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z"/></svg>
                </button>
                </a>
                
            </div>
            <div className='w-full  text-black md:py-16 py-8 flex px-10 items-center animate-fade-up'>
                <div className='xl:text-3xl lg:text-2xl md:text-xl  '>2.</div>
                <div className='xl:text-5xl lg:text-4xl md:text-2xl md:ml-10 ml-4 text-center'>Student trax</div>
                <div className='bg-black w-px md:ml-10 ml-5 text-black h-24' />
                <div className='xl:text-5xl lg:text-4xl md:text-base md:ml-10 ml-3 font-light max-sm'>Firebase, React, CSS </div>
                <a href='https://github.com/CodingDylan/student-tracker'><button className='md:text-3xl text-xl border hidden lg:block border-black border-solid rounded-xl items-center justify-center md:px-14 px-4 absolute md:py-6 py-2 right-10 md:right-20'>
                    Visit
                </button>
                </a>
                <a href='https://github.com/CodingDylan/student-tracker'><button className='block lg:hidden p-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13.5" viewBox="0 0 16 9"><path fill="currentColor" d="M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5"/><path fill="currentColor" d="M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71c.2-.2.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z"/></svg>
                </button>
                </a>
            </div>
            <div className='w-full mx-auto bg-black h-px '/>
            <div className='w-full  text-black md:py-16 py-8 flex px-10 items-center '>
                <div className='xl:text-3xl lg:text-2xl md:text-xl  '>3.</div>
                <div className='xl:text-5xl lg:text-4xl md:text-2xl md:ml-10 ml-4'>Gymbuddy website</div>
                <div className='bg-black w-px md:ml-10 ml-5 text-black h-24' />
                <div className='xl:text-5xl lg:text-4xl md:text-base md:ml-10 ml-3 font-light max-sm'>Next.js, Firebase, Tailwind CSS </div>
                <button className='md:text-3xl text-xl border hidden lg:block border-black border-solid rounded-xl items-center justify-center md:px-14 px-4 absolute md:py-6 py-2 right-10 md:right-20'>
                    Visit
                </button>
                <button className='block lg:hidden p-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13.5" viewBox="0 0 16 9"><path fill="currentColor" d="M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5"/><path fill="currentColor" d="M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71c.2-.2.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z"/></svg>
                </button>
                
            </div>
            <div className='w-fulls mx-auto bg-black h-px '/>
        </div>
        <div className='pb-20'>

        </div>
        
    </div>
  )
}

export default Projects