import React from 'react'
import "../App.css"

function FutureDesign() {


  return (
        <div className='bg-gray-200  whitespace-nowrap border border-solid border-black h-32 overflow-hidden md:text-8xl text-5xl text-black items-center text-center flex  '>
        <div className='carousel-track flex'>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
          <div className=''>THE FUTURE - </div>
        </div>
        
        </div>
  )
}

export default FutureDesign