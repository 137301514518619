import React from 'react'
import { Link } from 'react-router-dom'


function Header() {
  return (
    <div className='bg-gray-200 w-full pt-4'>
      <header className=" bg-white justify-between flex rounded-md shadow-md p-3  mx-auto items-center" style={{width: '96%',}}>
        <div className='flex  items-center'>
          <Link to='/'>
          <div className='rounded-lg items-center justify-center bg-black text-white sm:p-3 p-2 text-base sm:text-2xl'>
              DN
          </div>
          </Link>
          <div className='text-xl font-semibold ml-5 hidden md:block'>
            Dylan Ngere
          </div>
          
        </div>
        
        <div className='md:hidden font-bold  text-center sm:text-xl  text-base w-auto mx-auto'>Dylan Ngere</div>
        <div className='flex items-center'>
            <ul className=' hidden  md:flex mr-16'>
                <li className='mx-3 text-lg font-bold hover:text-neutral-300'>About</li>
                <Link to="/contactme"><li className='mx-3 ml-4 text-lg font-bold hover:text-neutral-300'>Contact</li></Link>
                {/* <Link to="/blog"><li className='mx-3 ml-4 text-lg font-bold hover:text-neutral-300'>Blog</li></Link> */}
            </ul>
            <a href='https://github.com/CodingDylan'>
              <button className='hidden md:block bg-black px-10 font-semibold  hover:bg-slate-800 text-white text-lg py-3 rounded-lg'>
                  My Projects
              </button>
            </a>
        </div>
        <div className="md:hidden"> 
      
      <button type="button" className="block text-black hover:text-white focus:text-white focus:outline-none">
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
          </svg>
        </button>
    </div>

    </header>

    </div>
    
  )
}

export default Header